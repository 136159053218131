import { defineStore } from "pinia";
import {
  collection,
  query,
  where,
  Timestamp,
  doc,
  updateDoc,
  addDoc,
  arrayUnion,
  orderBy,
  limit,
  getCountFromServer,
} from "firebase/firestore";
import { db, converter } from "@/firebase";
import { useFirestore } from "@vueuse/firebase/useFirestore";
import { useAdminStore } from "./admin";
import { computed, Ref, ref, watch } from "vue";
import { Job, JobSheet, Nullable } from "@/models";
import dayjs from "dayjs";
import { useToast } from "vue-toastification";
import * as Sentry from "@sentry/vue";
import { combinedTextSearch } from "@/helpers/firestore";
import debounce from 'lodash.debounce'

const toast = useToast();

export const useJobStore = defineStore("jobs", () => {
  const adminStore = useAdminStore();
  const userEnv = computed(() => adminStore.user?.environment);

  const selectedJobSheet: Ref<Nullable<JobSheet>> = ref(null);
  const selectedJob: Ref<Nullable<Job>> = ref(null);
  const filteredStatus: Ref<Nullable<string>> = ref(null);

  const searchText = ref('');

  function setSelectedJobSheet(job: JobSheet | null) {
    selectedJobSheet.value = job;
    if (!job) return;
    selectedJob.value = jobs.value?.find((j) => j.id === job.jobId) ?? null;
  }
  function setSelectedJob(job: Job) {
    selectedJob.value = job;
  }

  // all job sheets (paginated)

  const jobSheetsQueryCount = computed(() => {
    if (!!userEnv.value && !filteredStatus.value || filteredStatus.value === null) {
      return query(
        collection(db, `environments/${userEnv.value}/jobsheets`),
        orderBy("jobDate", "desc")
      ).withConverter(converter<JobSheet>())
    }
    return !!userEnv.value && !!filteredStatus.value &&
      query(
        collection(db, `environments/${userEnv.value}/jobsheets`),
        where('status', "==", filteredStatus.value),
        orderBy("jobDate", "desc")
      ).withConverter(converter<JobSheet>())
  }
  );

  const loadQueryCount = async () => {
    if (jobSheetsQueryCount.value && userEnv.value) {
      const data = await getCountFromServer(jobSheetsQueryCount.value)
      jobSheetCount.value = data.data().count
    }
  }

  const jobSheetCount = ref(0)
  const jobSheetsPage = ref(1)
  const jobSheetsPerPage = ref(300)

  const loadMore = () => {
    jobSheetsPerPage.value += 100
  }
  const clearToOnePage = () => {
    jobSheetsPerPage.value = 100
  }

  watch(
    userEnv,
    async (to) => {
      if (!!to && jobSheetCount.value === 0) {
        loadQueryCount();
      }
    },
  )

  watch(jobSheetsQueryCount, async (to) => {
    await loadQueryCount();
  }, { immediate: true });

  const jobSheetsQuery = computed(() => {
    let q = null

    if (filteredStatus.value === null) {
      q = query(
        collection(db, `environments/${userEnv.value}/jobsheets`),
        orderBy("jobDate", "desc"),
        limit(jobSheetsPerPage.value)
      ).withConverter(converter<JobSheet>())
      if (searchText.value === '') {
        return q;
      }
    }

    q = !!filteredStatus.value &&
      query(
        collection(db, `environments/${userEnv.value}/jobsheets`),
        where('status', "==", filteredStatus.value),
        orderBy("jobDate", "desc"),
        limit(jobSheetsPerPage.value)
      ).withConverter(converter<JobSheet>())

    if (jobSheetsPage.value > 1) {
      q = query(
        collection(db, `environments/${userEnv.value}/jobsheets`),
        where('status', "==", filteredStatus.value),
        orderBy("jobDate", "desc"),
        limit(jobSheetsPerPage.value)
      ).withConverter(converter<JobSheet>())
    }
    return q;
  });

  let jobSheetsRaw = ref<JobSheet[] | undefined>([]);
  try {
    jobSheetsRaw = useFirestore<JobSheet>(jobSheetsQuery, []);
  } catch (error) {
    Sentry.captureException(error);
  }

  const searchingJobsheetsOnServer = ref(false)

  watch(
    searchText,
    debounce(async () => {
      // Add a debouncer here

      if (searchText.value === '') {
        searchJobSheets.value = []
        return;
      }

      try {
        searchingJobsheetsOnServer.value = true

        const results = await combinedTextSearch({
          db: db,
          path: `environments/${userEnv.value}/jobsheets`,
          fields:
            [
              { name: 'searchAddress', type: 'string' },
              { name: 'searchJobNumber', type: 'string' },
              { name: 'searchInstructions', type: 'string' },
              { name: 'searchCustomerName', type: 'string' },
              { name: 'searchDocketNumbers', type: 'array' },
            ],
          searchText: searchText.value.toLowerCase(),
          converter: converter<JobSheet>(),
          limitBy: 15
        })
        searchJobSheets.value = results.filter((j) => !j.deleted).sort((a, b) => b.jobDate.toMillis() - a.jobDate.toMillis())

      } catch (error) {
        Sentry.captureException(error);
      } finally {
        searchingJobsheetsOnServer.value = false
      }
    }, 500)
  )

  const searchJobSheets = ref<JobSheet[]>([])

  const jobSheets = computed(() =>
    jobSheetsRaw.value?.filter((j) => !j.deleted)
  );

  //TODO: This isn't sustainable, task in click up.
  // all jobs (limit 200 days)

  const daysAgo = 200;
  const startQueryDate = Timestamp.fromDate(
    dayjs().subtract(daysAgo, "days").toDate()
  );
  const jobsQuery = computed(() =>
    query(
      collection(db, `environments/${userEnv.value}/jobs`),
      orderBy("createdAt", "desc"),
    ).withConverter(converter<Job>())
  );
  const jobs = useFirestore<Job>(jobsQuery);

  const selectedJobSheetsQuery = computed(
    () =>
      !!selectedJob.value?.id &&
      query(
        collection(db, `environments/${userEnv.value}/jobsheets`),
        where("jobId", "==", selectedJob.value?.id)
      ).withConverter(converter<JobSheet>())
  );
  const selectedJobSheets = useFirestore<JobSheet>(selectedJobSheetsQuery);

  const todaysJobSheetsQuery = computed(() => {
    const todayDate = Timestamp.fromDate(dayjs().hour(0).minute(0).toDate());
    return query(
      collection(db, `environments/${userEnv.value}/jobsheets`),
      where("jobDate", ">=", todayDate)
    ).withConverter(converter<JobSheet>());
  });
  const todaysJobSheetsRaw = useFirestore<JobSheet>(todaysJobSheetsQuery);
  const todaysJobSheets = computed(() =>
    todaysJobSheetsRaw.value
      ?.filter((j) => !["Completed", "Approved"].includes(j.status))
      .filter((j) => !j.deleted)
  );

  // my job sheets
  const myJobSheets = computed(() =>
    todaysJobSheets.value?.filter((j) => j.operator.id === adminStore.user?.id)
  );

  // filtered job sheets with status
  const filteredJobSheets = computed(() => {
    return jobSheets.value;
  });

  // save partial jobsheet update

  async function updateJobSheet(jobsheet: Partial<JobSheet>) {
    if (!jobsheet.id) return;
    const operatorRef = doc(
      db,
      `environments/${userEnv.value}/jobsheets`,
      jobsheet.id
    );

    // if(jobsheet.lineItems && jobsheet.lineItems?.length > 0 && jobsheet.lineItems.some((LI) => LI.files)) {
    //   console.log("Removing URL")
    //   jobsheet.lineItems.forEach((li) => li.files?.forEach((file) => {file.temporaryURL = ''}))
    // }

    return await updateDoc(operatorRef, {
      ...jobsheet,
      // Search fields
      searchCustomerName: (jobsheet.customer?.Name.trim() ?? "").toLowerCase(),
      searchJobNumber: (jobsheet.jobNumber?.trim() ?? "").toLowerCase(),
      searchAddress: (jobsheet.address?.trim() ?? "").toLowerCase(),
      searchInstructions: (jobsheet.instructions?.trim() ?? "").toLowerCase(),
      searchDocketNumbers: jobsheet.lineItems?.map((l) => l.docketNumber.trim().toLowerCase()) ?? [],
      updatedAt: new Date(),
    }).catch((error) => {
      Sentry.captureException(error, {
        extra: {
          jobsheet,
          userEnv: userEnv.value,
        },
      });
      toast.error(`An error occured while updating jobsheet: ${error}`);
    });
  }

  async function createJobSheet(
    jobsheet: Partial<JobSheet>,
    isDuplicate: boolean = false
  ): Promise<boolean> {
    const jobsRef = `environments/${userEnv.value}/jobs`;
    const jobSheetsRef = `environments/${userEnv.value}/jobsheets`;
    // if its a new job, create the job reference first
    if (!jobsheet.jobId) {
      try {
        const newJobRecord = await addDoc(collection(db, jobsRef), {
          address: jobsheet.address,
          customer: jobsheet.customer,
          customerName: jobsheet.customer?.Name, // Flatten so we can search for name
          jobNumber: jobsheet.jobNumber,
          jobSheets: [],
          createdAt: new Date(),
          // Search fields
          searchCustomerName: (jobsheet.customer?.Name.trim() ?? "").toLowerCase(),
          searchJobNumber: (jobsheet.jobNumber?.trim() ?? "").toLowerCase(),
          searchAddress: (jobsheet.address?.trim() ?? "").toLowerCase(),
          searchInstructions: (jobsheet.instructions?.trim() ?? "").toLowerCase(),
        });
        jobsheet.jobId = newJobRecord.id;
      } catch (error) {
        toast.error(`An error occured while creating jobsheet: ${error}`);
        Sentry.captureException(error);
        return false;
      }
    }
    // now create the actual jobsheet
    try {
      let jobDate = jobsheet.jobDate?.toDate() ?? new Date();
      // If jobdate is in the future use that date, if in the past use todays date.
      if (dayjs(jobDate).isBefore(dayjs(), "day")) {
        jobDate = new Date();
      }
      const newJobsheet = await addDoc(collection(db, jobSheetsRef), {
        ...jobsheet,
        id: null,
        createdAt: new Date(),
        status: "New",
        isInvoiced: false,
        notes: [],
        lineItems: isDuplicate ? [] : (jobsheet.lineItems ?? []),
        operator: isDuplicate ? null : jobsheet.operator,
        machineType: isDuplicate ? null : jobsheet.machineType,
        jobDate,
        // Search fields
        searchCustomerName: (jobsheet.customer?.Name.trim() ?? "").toLowerCase(),
        searchJobNumber: (jobsheet.jobNumber?.trim() ?? "").toLowerCase(),
        searchAddress: (jobsheet.address?.trim() ?? "").toLowerCase(),
        searchInstructions: (jobsheet.instructions?.trim() ?? "").toLowerCase(),
        searchDocketNumbers: jobsheet.lineItems?.map((l) => l.docketNumber.trim().toLowerCase()) ?? [],
      });
      // add the jobsheet to the job parent
      const jobDoc = doc(
        db,
        `environments/${userEnv.value}/jobs`,
        jobsheet.jobId!
      );
      await updateDoc(jobDoc, {
        jobSheets: arrayUnion(newJobsheet.id),
      });
      if (!isDuplicate) toast.success("New jobsheet created");
    } catch (error) {
      Sentry.captureException(error);
      toast.error(`An error occured while creating jobsheet: ${error}`);
      return false;
    }
    return true;
  }

  async function deleteJobSheet(jobsheet: JobSheet) {
    updateJobSheet({
      id: jobsheet.id,
      deleted: true,
    });
    toast.success("Deleted jobsheet");
  }

  const clear = () => {
    selectedJobSheet.value = null;
    selectedJob.value = null;
    filteredStatus.value = null;
  }

  return {
    setSelectedJobSheet,
    setSelectedJob,
    updateJobSheet,
    createJobSheet,
    deleteJobSheet,
    clear,
    loadMore,
    clearToOnePage,
    searchingJobsheetsOnServer,
    searchJobSheets,
    searchText,
    jobSheetsPage,
    jobSheetsPerPage,
    jobSheetCount,
    jobSheets,
    jobs,
    filteredStatus,
    filteredJobSheets,
    todaysJobSheets,
    myJobSheets,
    selectedJobSheet,
    selectedJob,
    selectedJobSheets,
  };
});
