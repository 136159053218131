import { defineStore } from "pinia";
import { doc, query, collection, updateDoc, addDoc } from "firebase/firestore";
import { db, converter } from "@/firebase";
import { useFirestore } from "@vueuse/firebase/useFirestore";
import { useAdminStore } from "./admin";
import { computed } from "vue";
import { Environment, Operator } from "@/models";
import { useToast } from "vue-toastification";
import * as Sentry from "@sentry/vue";

const toast = useToast();

export const useOperatorStore = defineStore("operators", () => {
  const adminStore = useAdminStore();
  const userEnv = computed(() => adminStore.user?.environment);

  const operatorsQuery = computed(
    () =>
      !!userEnv.value &&
      query(
        collection(db, `environments/${userEnv.value}/operators`)
      ).withConverter(converter<Operator>())
  );
  const operators = useFirestore<Operator>(operatorsQuery);

  async function saveOperator(operator: Operator): Promise<void> {
    const operatorRef = doc(
      db,
      `environments/${userEnv.value}/operators`,
      operator.id
    );
    return await updateDoc(operatorRef, {
      ...operator,
      updatedAt: new Date(),
    });
  }
  async function resetPassword(
    operator: Operator,
    password: string
  ): Promise<void> {
    try {
      // password too short...
      if (password.trim().length < 5) return;
      await saveOperator({
        plainPassword: password,
        ...operator,
      });
      toast.success(
        `Password reset for user ${operator.firstName} ${operator.lastName}`
      );
    } catch (error) {
      Sentry.captureException(error);
      toast.error(`An error occured while resetting password: ${error}`);
    }
  }
  async function createOperator(operator: Partial<Operator>): Promise<void> {
    try {
      if (!(operator.firstName && operator.lastName)) return;
      const collectionRef = `environments/${userEnv.value}/operators`;
      await addDoc(collection(db, collectionRef), {
        ...operator,
        updatedAt: new Date(),
      });
      toast.success(
        `Added operator ${operator.firstName} ${operator.lastName}`
      );
    } catch (error) {
      Sentry.captureException(error);
      toast.error(`An error occured while adding operator: ${error}`);
    }
  }

  const clear = () => {
    operators.value = [];
  }

  return {
    operators,
    clear,
    saveOperator,
    createOperator,
    resetPassword,
  };
});

export interface User {
  id: string;
  environment: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  username: string;
}
