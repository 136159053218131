<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  Bars3BottomLeftIcon,
  BellIcon,
  BriefcaseIcon,
  BanknotesIcon,
  HomeIcon,
  InboxIcon,
  UsersIcon,
  XMarkIcon,
  UserGroupIcon,
} from "@heroicons/vue/24/outline";
import profileImage from "@/assets/images/profile.png";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/auth";
import { useAdminStore } from "@/stores/admin";

const authStore = useAuthStore();
const adminStore = useAdminStore()

const router = useRouter();
const routeName = computed(() => {
  return router.currentRoute.value.name;
});
const routePath = computed(() => {
  return router.currentRoute.value.path;
});

const appVersion = computed(() => {
  return authStore.appVersion;
})

const appEnvironment = computed(() => {
  return adminStore.environment?.name ?? '';
})

const navigation = [
  { name: "Dashboard", href: "/dashboard", icon: HomeIcon },
  {
    name: "Job Sheets",
    href: "/job-sheets",
    icon: BriefcaseIcon,
    current: false,
  },
  { name: "Operators", href: "/operators", icon: UsersIcon },
  { name: "Invoices", href: "/invoices", icon: BanknotesIcon },
  { name: "Admin", href: "/admin", icon: InboxIcon },
  { name: "Users", href: "/users", icon: UserGroupIcon },
];
const userNavigation = [
  { name: "Settings", href: "/settings" },
  { name: "Sign out", href: "/signout" },
];

const sidebarOpen = ref(false);
</script>

<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        class="relative z-40 md:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>

        <div class="fixed inset-0 z-40 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel
              class="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4"
            >
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    @click="sidebarOpen = false"
                  >
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <div class="px-4">
                <h3 class="text-sm font-bold text-zinc-800">
                  {{ appEnvironment }}
                </h3>
                <h2 class="text-2xl font-bold text-red-500">Intransvoice</h2>
                <div class="text-xs">Version: {{ appVersion }}</div>
              </div>
              <div class="mt-5 h-0 flex-1 overflow-y-auto">
                <nav class="space-y-1 px-2">
                  <routerLink
                    v-for="item in navigation"
                    @click="sidebarOpen = false"
                    :key="item.name"
                    :to="item.href"
                    :class="[
                      item.name === routeName
                        ? 'bg-red-100 text-red-600'
                        : 'text-gray-600 hover:bg-red-50 hover:text-red-500',
                      'group rounded-sm py-2 px-2 flex items-center text-base font-medium',
                    ]"
                  >
                    <component
                      :is="item.icon"
                      :class="[
                        item.name === routeName
                          ? 'text-red-500'
                          : 'text-gray-400 group-hover:text-red-400',
                        'mr-4 flex-shrink-0 h-6 w-6',
                      ]"
                      aria-hidden="true"
                    />
                    {{ item.name }}
                  </routerLink>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
          <div class="w-14 flex-shrink-0">
            <!-- Dummy element to force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
      <div
        class="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5"
      >
        <div class="px-4">
          <h3 class="text-sm font-bold text-zinc-800">{{ appEnvironment }}</h3>
          <h2 class="text-2xl font-bold text-red-500">Intransvoice</h2>
          <div class="text-xs">Version: {{ appVersion }}</div>
        </div>
        <div class="mt-5 flex flex-grow flex-col">
          <nav class="flex-1 space-y-1 px-2 pb-4">
            <routerLink
              v-for="item in navigation"
              :key="item.name"
              :to="item.href"
              @click="sidebarOpen = false"
              :class="[
                item.name === routeName
                  ? 'bg-red-100 text-red-600'
                  : 'text-gray-600 hover:bg-red-50 hover:text-red-500',
                'group rounded-sm py-2 px-2 flex items-center text-sm font-medium relative',
              ]"
              ><div class="h-7 w-7 flex ml-3 items-center justify-center">
                <component
                  :is="item.icon"
                  :class="[
                    item.name === routeName
                      ? 'text-red-500 h-7 w-7'
                      : 'text-gray-400 group-hover:text-red-400 h-6 w-6 group-hover:h-7 group-hover:w-7 duration-100',
                    'mr-4 flex-shrink-0 h-6 w-6',
                  ]"
                  aria-hidden="true"
                />
              </div>
              <p class="ml-2">{{ item.name }}</p>
            </routerLink>
          </nav>
        </div>
      </div>
    </div>

    <div class="md:pl-64 bg-slate-50 h-screen">
      <div
        class="md:mx-5 xl:mx-10 flex flex-col md:px-8 xl:px-0 min-h-0 h-full relative"
      >
        <div
          class="sticky md:static top-0 z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-slate-50"
        >
          <button
            type="button"
            class="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500 md:hidden"
            @click="sidebarOpen = true"
          >
            <span class="sr-only">Open sidebar</span>
            <Bars3BottomLeftIcon class="h-6 w-6" aria-hidden="true" />
          </button>
          <div class="flex flex-1 items-center justify-between px-4 md:px-0">
            <h1 class="text-xl font-semibold text-gray-900">
              {{ routeName }}
            </h1>
            <!-- <div class="flex flex-1 ml-10">
              <form class="flex w-full md:ml-0" action="#" method="GET">
                <label for="search-field" class="sr-only">Search</label>
                <div
                  class="relative w-full text-gray-400 focus-within:text-gray-600"
                >
                  <div
                    class="pointer-events-none absolute inset-y-0 left-0 flex items-center"
                  >
                    <MagnifyingGlassIcon class="h-5 w-5" aria-hidden="true" />
                  </div>
                  <input
                    id="search-field"
                    class="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
                    placeholder="Search"
                    type="search"
                    name="search"
                  />
                </div>
              </form>
            </div> -->
            <div class="ml-4 flex items-center md:ml-6">
              <!-- <button
                type="button"
                class="rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                <span class="sr-only">View notifications</span>
                <BellIcon class="h-6 w-6" aria-hidden="true" />
              </button> -->

              <!-- Profile dropdown -->
              <Menu as="div" class="relative ml-3">
                <div>
                  <MenuButton
                    class="flex max-w-xs items-center rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    <span class="sr-only">Open user menu</span>
                    <img
                      class="h-8 w-8 rounded-full"
                      :src="profileImage"
                      alt=""
                    />
                  </MenuButton>
                </div>
                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-sm bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <MenuItem
                      v-for="item in userNavigation"
                      :key="item.name"
                      v-slot="{ active, close }"
                    >
                      <routerLink
                        :to="item.href"
                        @click="close"
                        :class="[
                          active ? 'bg-gray-100' : '',
                          'block py-2 px-4 text-sm text-gray-700',
                        ]"
                        >{{ item.name }}</routerLink
                      >
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
        </div>

        <main class="flex-1 h-full overflow-y-scroll min-h-0 sm:py-6">
          <RouterView />
        </main>
      </div>
    </div>
  </div>
</template>
