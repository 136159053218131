import { defineStore } from "pinia";
import { doc, query, collection, updateDoc, addDoc, where, setDoc } from "firebase/firestore";
import { db, converter, auth } from "@/firebase";
import { useFirestore } from "@vueuse/firebase/useFirestore";
import { useAdminStore } from "./admin";
import { computed } from "vue";
import { FBUser, Operator } from "@/models";
import { useToast } from "vue-toastification";
import { createUserWithEmailAndPassword, deleteUser, getAuth, sendPasswordResetEmail, UserCredential } from "firebase/auth";
import dayjs from "dayjs";
import * as Sentry from "@sentry/vue";

const toast = useToast();

export const useUsersStore = defineStore("users", () => {
  const adminStore = useAdminStore();
  const companyEnv = computed(() => adminStore.user?.environment);

  const usersQuery = computed(
    () =>
      !!companyEnv.value &&
      query(
        collection(db, `users`), where('environment', '==', companyEnv.value)
      ).withConverter(converter<FBUser>())
  );

  const users = useFirestore<FBUser>(usersQuery);

  // Add users
  async function createUser(user: Partial<FBUser>, environment: string): Promise<void> {
    const { email } = user;
    if (!email) {
      toast.error('Email is required');
      return;
    }
    const password = dayjs().valueOf().toString();
    let uid!: string;
    try {
      const result = await createUserWithEmailAndPassword(auth, email, password);
      if (result) {
        uid = (result as UserCredential).user.uid;
      }
    } catch (error) {
      // TODO: Handle error
    }

    if (!uid) {
      return;
    }

    const userRef = doc(db, "users", uid);
    try {
      await setDoc(userRef, {
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.username,
        email: user.email,
        role: 'admin',
        environment,
      })
    } catch (error) {
      // TODO: Handle error
    }

    // Send password reset
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      Sentry.captureException(error);
      toast.error(`Error trying to reset password ${JSON.stringify(error)}`)
      // TODO: Handle error
    }
  }

  const updateUser = (user: FBUser) => {
    try {
      updateDoc(doc(db, "users", user.id), {
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.username,
      });
      toast.success(
        `User details updated for ${user.firstName} ${user.lastName}`
      );
    } catch (error) {
      Sentry.captureException(error);
      toast.error(`An error occured while updating user details: ${error}`);
    }
  }
  
  const removeUser = async (user: FBUser) => {
    // TODO: Remove user
  }

  const resetUserPassword = async (email: string) => {
    try {
      const actionCodeSettings = {
        url: 'https://app.intransvoice.co.nz/signin' ,
        handleCodeInApp: false
      };
      const auth = getAuth(); 
      await sendPasswordResetEmail(auth, email, actionCodeSettings);
      toast.success('Password reset email sent successfully.');
    } catch (error) {
      toast.error(`An error occurred while resetting user password: ${error}`);
    }
  };

  const clear = () => {
    users.value = [];
  }


  return {
    resetUserPassword,
    users,
    createUser,
    updateUser,
    clear,
    // createOperator,
    // resetPassword,
  };
});