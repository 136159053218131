import { useArrayIncludes } from "@vueuse/core";
import { FirebaseApp } from "firebase/app";
import { Firestore, collection, getDoc, getDocs, limit, query, where } from "firebase/firestore";

import * as Sentry from "@sentry/vue";

export interface SearchOptions {
  db: Firestore
  path: string;
  searchText: string;
  fields: SearchField[];
  converter: any;
  limitBy: number;
}

export interface SearchField {
  name: string;
  type: string;
}

export const combinedTextSearch = async (options: SearchOptions) => {
  const { db, path, searchText, fields, converter, limitBy } = options;
  const colRef = collection(db, path);

  const combinedResults: any[] = [];
  
  for await (const field of fields) {
    try {
      if (field.type === 'array') {
        const q = query(
          colRef,
          where(field.name, "array-contains", searchText),
          limit(limitBy)
        ).withConverter(converter)
        const results = await getDocs(q);
        if (results.docs.length > 0) {
          results.forEach((doc) => {
            combinedResults.push({...doc.data(), id: doc.id})
          })
        }
      } else {
        const q = query(
          colRef,
          where(field.name, '>=', searchText),
          where(field.name, '<=', searchText + 'z'),
          limit(limitBy)
        ).withConverter(converter)
        const results = await getDocs(q);
        if (results.docs.length > 0) {
          results.forEach((doc) => {
            combinedResults.push({...doc.data(), id: doc.id})
          })
        }
      }
      
    } catch (error) {
      console.error(error)
      Sentry.captureException(error);
    }
  }

  // Make resutls unique and return
  return combinedResults.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
}