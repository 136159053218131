import { createApp, markRaw } from "vue";
import "./style.css";
import App from "./App.vue";
import { createPinia } from "pinia";
import router from "@/router";
import Toast, { PluginOptions } from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueCameraLib from 'vue-camera-lib'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

const pinia = createPinia();

const toastOptions: PluginOptions = {
  timeout: 2500,
};

const app = createApp(App);
Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["localhost", "intransvoice.web.app", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
});

app.use(VueCameraLib);
app.use(Toast, toastOptions);
app.use(pinia);
app.use(router);
app.mount("#app");
