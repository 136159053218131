import { defineStore, storeToRefs } from "pinia";
import { computed, ref, Ref } from "vue";
import {
  getAuth,
  signInWithEmailAndPassword,
  User,
  signOut,
  signInWithCustomToken,
} from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { FBUser, Nullable } from "@/models";
import { app, converter, db, funcs } from "@/firebase";
import router from "@/router";
import { useOperatorAdminStore } from "@/stores/operatorRole";
import { useToast } from "vue-toastification";
import { doc } from "firebase/firestore";
import { useFirestore } from "@vueuse/firebase";
import { useAdminStore } from "./admin";
import { useOperatorStore } from "./operators";
import { useUsersStore } from "./users";
import { useJobStore } from "./jobs";
import * as Sentry from "@sentry/vue";

const toast = useToast();

export const useAuthStore = defineStore("auth", () => {
  const auth = getAuth(app);
  const user: Ref<Nullable<User>> = ref(null);
  const fbUser: Ref<Nullable<FBUser>> = ref(null);
  const authError = ref("");
  const isOperator = ref(false);

  // @ts-ignore
  const versionNumber = PACKAGE_VERSION || "0";

  async function getCurrentUser(): Promise<User | null> {
    return new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        unsubscribe();
        resolve(user);
      }, reject);
    });
  }

  async function loadUserIntoState(userObj: User) {
    user.value = userObj;
    await getUserData(user.value.uid);
  }

  async function signinUser(email: string, password: string) {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      user.value = userCredential.user;
      if (user.value.uid) {
        await getUserData(user.value.uid);
        router.push("/dashboard");
      }
    } catch (error: any) {
      Sentry.captureException(error);
      authError.value = error.message;
    }
  }

  const getUserData = async (uid: string) => {
    try {
      const userQuery = doc(db, "users", uid).withConverter(converter<FBUser>())
      const user = useFirestore<FBUser>(userQuery);
      if (user.value) {
        fbUser.value = user.value;
        useAdminStore().setEnvID(user.value.environment);
      }
    } catch (error: any) {
      Sentry.captureException(error);
      authError.value = error.message;
    }
  }

  async function signinOperator(
    email: string,
    password: string,
    envIdInput?: string
  ) {
    try {
      const operatorAdminStore = useOperatorAdminStore();
      const { envId } = storeToRefs(operatorAdminStore);
      const login = httpsCallable(funcs, "admin-authOperator");
      const result = (await login({
        userName: email,
        password,
        environmentId: envIdInput,
      })) as {
        data: {
          data: any;
          message: string;
          success: boolean;
        };
      };
      if (result.data.success === false) {
        authError.value = result.data.message;
        throw new Error(result.data.message);
      }

      const res = await signInWithCustomToken(auth, result.data.data.token);
      user.value = res.user;
      isOperator.value = true;
      envId.value = result.data.data.environmentId;
      if (user.value.uid) {
        router.push(`/operator/${envId.value}`);
      }
    } catch (error: any) {
      Sentry.captureException(error);
      authError.value = error.message;
    }
  }

  const clear = () => {
    user.value = null;
    fbUser.value = null;
    authError.value = "";
    isOperator.value = false;
  }

  async function signoutUser() {
    signOut(auth)
      .then(() => {
        // Clear stores
        clear();
        useAdminStore().clear();
        useOperatorStore().clear();
        useUsersStore().clear();
        useJobStore().clear();
        return
        // if (isOperator.value) router.push("/signin/fiveash");
        // else router.push("/signin");
      })
      .catch((error) => {
        Sentry.captureException(error);
        toast.error(`An error occured while signing out: ${error}`);
        throw error;
      });
  }
  
  const authenticatedUser = computed(() => user.value);

  const appVersion = computed(() => {
    if (import.meta.env.DEV) {
      return `dev-${versionNumber}`;
    }
    return versionNumber;
  });

  return {
    auth,
    fbUser,
    appVersion,
    isOperator,
    authError,
    authenticatedUser,
    signoutUser,
    getCurrentUser,
    signinUser,
    signinOperator,
    loadUserIntoState,
  };
});
