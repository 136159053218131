import { defineStore, storeToRefs } from "pinia";
import {
  doc,
  query,
  collection,
  Timestamp,
  where,
  updateDoc,
} from "firebase/firestore";
import { db, converter } from "@/firebase";
import { useFirestore } from "@vueuse/firebase/useFirestore";
import { useAuthStore } from "./auth";
import { useAdminStore } from "./admin";
import { computed, Ref, ref } from "vue";
import { Environment, Job, JobSheet, Nullable } from "@/models";
import dayjs from "dayjs";
import { useRoute } from "vue-router";

export const useOperatorAdminStore = defineStore("operator-admin", () => {
  const authStore = useAuthStore();
  const adminStore = useAdminStore();
  const { isOperator } = storeToRefs(authStore);
  const { envId } = storeToRefs(adminStore);
  isOperator.value = true;
  const userId = computed(() => authStore.authenticatedUser?.uid);

  const route = useRoute();

  if (!envId.value && route) {
    envId.value = route.params?.envid as string;
  }

  const userQuery = computed(
    () =>
      !!userId.value &&
      doc(
        db,
        `environments/${envId.value}/operators`,
        userId.value
      ).withConverter(converter<OperatorUser>())
  );
  const operatorUser = useFirestore<OperatorUser>(userQuery);

  const envQuery = computed(
    () =>
      !!envId.value &&
        doc(db, `environments/${envId.value}`)
      .withConverter(converter<Environment>())
  );
  const environment = useFirestore<Environment>(envQuery);

  const selectedJobSheet: Ref<Nullable<JobSheet>> = ref(null);
  const selectedJob: Ref<Nullable<Job>> = ref(null);

  const jobsQuery = computed(() =>
    query(collection(db, `environments/${envId.value}/jobs`)).withConverter(
      converter<Job>()
    )
  );
  const jobs = useFirestore<Job>(jobsQuery);

  function setSelectedJobSheet(job: JobSheet | null) {
    selectedJobSheet.value = job;
    if (!job) return;
    selectedJob.value = jobs.value?.find((j) => j.id === job.jobId) ?? null;
  }

  const selectedJobSheetComputed = computed(() => todaysJobSheetsRaw.value?.find((js) => js.id === selectedJobSheet.value?.id) ?? null);

  const todaysJobSheetsQuery = computed(() => {
    const todayDate = Timestamp.fromDate(dayjs().hour(0).minute(0).second(0).toDate());
    return query(
      collection(db, `environments/${envId.value}/jobsheets`),
      where("jobDate", ">", todayDate),
      where("jobDate", "<=", Timestamp.fromDate(dayjs().hour(23).minute(59).toDate()))
    ).withConverter(converter<JobSheet>());
  });
  const todaysJobSheetsRaw = useFirestore<JobSheet>(todaysJobSheetsQuery);
  const todaysJobSheets = computed(() =>
    todaysJobSheetsRaw.value
      ?.filter((j) => !["Completed", "Approved"].includes(j.status))
      .filter((j) => !j.deleted)
  );

  // my job sheets
  const myJobSheets = computed(() =>
    todaysJobSheets.value?.filter(
      (j) => j.operator?.id === operatorUser.value?.id
    )
  );

  // save partial jobsheet update

  async function updateJobSheet(jobsheet: Partial<JobSheet>) {
    if (!jobsheet.id) return;
    const operatorRef = doc(
      db,
      `environments/${envId.value}/jobsheets`,
      jobsheet.id
    );
    if (!jobsheet.notes || jobsheet.notes.length === 0) {
      delete jobsheet.notes; // Remove notes if empty so we don't override on server
    }
    await updateDoc(operatorRef, {
      ...jobsheet,
      updatedAt: new Date(),
    });
  }

  return {
    userId,
    envId,
    operatorUser,
    selectedJobSheet,
    myJobSheets,
    environment,
    selectedJobSheetComputed,
    setSelectedJobSheet,
    updateJobSheet,
  };
});

export interface OperatorUser {
  id: string;
  email: string;
  enabled: boolean;
  firstName: string;
  lastName: string;
  username: string;
}
