// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  DocumentData,
  getFirestore,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA5toHAzX1O0EK1tnIw9WGUjunvYpQTois",
  authDomain: "intransvoice.firebaseapp.com",
  projectId: "intransvoice",
  storageBucket: "intransvoice.appspot.com",
  messagingSenderId: "680557369550",
  appId: "1:680557369550:web:45acbe38077f7701de189f",
  measurementId: "G-CYCFBB2T8D",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const funcs = getFunctions(app);
export const auth = getAuth(app);

export const converter = <T>() => ({
  toFirestore: (data: any) => data,
  fromFirestore: (snap: QueryDocumentSnapshot) => {
    return { id: snap.id, ...snap.data() } as T;
  },
});
