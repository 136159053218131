import { defineStore } from "pinia";
import { doc, query, collection, updateDoc, addDoc, setDoc, orderBy } from "firebase/firestore";
import { db, converter, auth } from "@/firebase";
import { useFirestore } from "@vueuse/firebase/useFirestore";
import { useAuthStore } from "./auth";
import { computed, Ref, ref, watch } from "vue";
import {
  Account,
  Company,
  Description,
  Environment,
  MachineType,
  Product,
} from "@/models";

export const useAdminStore = defineStore("admin", () => {
  const authStore = useAuthStore();
  const authUser = authStore.authenticatedUser;

  const userId = ref(authUser?.uid);
  const userQuery = computed(
    () =>
      !!userId.value &&
      doc(db, "users", userId.value).withConverter(converter<User>())
  );
  const user = useFirestore<User>(userQuery);

  const envQuery = computed(
    () =>
      !!user.value?.environment &&
      doc(db, "environments", user.value?.environment).withConverter(
        converter<Environment>()
      )
  );
  const environment = useFirestore<Environment>(envQuery);
  watch(environment, () => {
    if (environment.value?.id) {
      envId.value = environment.value.id;
    }
  });

  const envId: Ref<string | undefined> = ref();

  const productsQuery = computed(
    () =>
      !!envId.value &&
      query(
        collection(db, `environments/${envId.value}/products`), orderBy('Description')
      ).withConverter(converter<Product>())
  );
  const products = useFirestore<Product>(productsQuery);

  const machinesQuery = computed(
    () =>
      !!envId.value &&
      query(
        collection(db, `environments/${envId.value}/machinetypes`), orderBy('description')
      ).withConverter(converter<MachineType>())
  );
  const machineTypes = useFirestore<MachineType>(machinesQuery);

  const descriptionsQuery = computed(
    () =>
      !!envId.value &&
      query(
        collection(db, `environments/${envId.value}/descriptions`), orderBy('description')
      ).withConverter(converter<Description>())
  );
  const descriptions = useFirestore<Description>(descriptionsQuery);

  const accountsQuery = computed(
    () =>
      !!envId.value &&
      query(
        collection(db, `environments/${envId.value}/accounts`), orderBy('name')
      ).withConverter(converter<Account>())
  );

  let accounts: Ref<Account[] | undefined> = useFirestore<Account>(accountsQuery);

  const companiesQuery = computed(
    () =>
      !!envId.value &&
      query(
        collection(db, `environments/${envId.value}/companies`)
      ).withConverter(converter<Company>())
  );
  
  const companies = useFirestore<Company>(companiesQuery);

  const setEnvID = (id: string) => {
    envId.value = id;
  }

  async function saveProduct(product: Product): Promise<void> {
    const operatorRef = doc(
      db,
      `environments/${envId.value}/products`,
      product.id
    );
    return await updateDoc(operatorRef, {
      ...product,
      updatedAt: new Date(),
    });
  }

  async function saveMachine(machine: MachineType): Promise<void> {
    const machineRef = doc(
      db,
      `environments/${envId.value}/machinetypes`,
      machine.id
    );
    return await updateDoc(machineRef, {
      ...machine,
      updatedAt: new Date(),
    });
  }

  async function saveEnvironment(environment: Environment): Promise<void> {
    const envRef = doc(db, `environments`, environment.id);
    return await updateDoc(envRef, {
      ...environment,
      updatedAt: new Date(),
    });
  }

  async function createDescription(
    description: Partial<Description>
  ): Promise<void> {
    const collectionRef = `environments/${envId.value}/descriptions`;
    await addDoc(collection(db, collectionRef), {
      ...description,
      updatedAt: new Date(),
    });
    return;
  }
  async function createMachine(machine: Partial<MachineType>): Promise<void> {
    const collectionRef = `environments/${envId.value}/machinetypes`;
    await addDoc(collection(db, collectionRef), {
      ...machine,
      updatedAt: new Date(),
    });
    return;
  }
  async function createAccount(account: Partial<Account>): Promise<void> {
    const collectionRef = `environments/${envId.value}/accounts`;
    await addDoc(collection(db, collectionRef), {
      ...account,
      updatedAt: new Date(),
    });
    return;
  }

  async function saveAccount(account: Account): Promise<void> {
    const accountRef = doc(
      db,
      `environments/${envId.value}/accounts`,
      account.id
    );
    return await updateDoc(accountRef, {
      ...account,
      updatedAt: new Date(),
    });
  }

  async function saveDescription(description: Description): Promise<void> {
    const descriptionRef = doc(
      db,
      `environments/${envId.value}/descriptions`,
      description.id
    );
    return await updateDoc(descriptionRef, {
      ...description,
      updatedAt: new Date(),
    });
  }

  const clear = () => {
    user.value = undefined;
    environment.value = undefined;
    envId.value = undefined;
    products.value = [];
    machineTypes.value = [];
    descriptions.value = [];
    accounts.value = [];
    companies.value = [];
  }

  return {
    user,
    environment,
    envId,
    products,
    machineTypes,
    descriptions,
    accounts,
    companies,
    setEnvID,
    saveProduct,
    saveMachine,
    saveAccount,
    saveEnvironment,
    saveDescription,
    createDescription,
    createAccount,
    createMachine,
    clear
  };
});

export interface User {
  id: string;
  environment: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  username: string;
}
