import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import LayoutViewVue from "../features/Shared/Views/LayoutView.vue";
import { useAuthStore } from "@/stores/auth";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "app",
    component: LayoutViewVue,
    meta: {
      authGuard: true,
    },
    children: [
      {
        name: "Home",
        path: "",
        redirect: "dashboard",
      },
      {
        path: "dashboard/:jobsheet?",
        name: "Dashboard",
        component: () => import("../features/Dashboard/DashboardView.vue"),
      },
      {
        path: "job-sheets/:jobsheet?",
        name: "Job Sheets",
        component: () => import("../features/Jobs/JobSheetsView.vue"),
      },
      {
        path: "operators",
        name: "Operators",
        component: () => import("../features/Operators/OperatorsView.vue"),
      },
      {
        path: "admin",
        name: "Admin",
        component: () => import("../features/Admin/AdminView.vue"),
      },
      {
        path: "users",
        name: "Users",
        component: () => import("../features/Users/UsersView.vue"),
      },
      {
        path: "invoices",
        name: "Invoices",
        component: () => import("../features/Invoices/InvoiceView.vue"),
        children: [
          {
            path: ":invoiceid",
            name: "Invoice",
            component: () => import("../features/Invoices/InvoiceView.vue"),
          },
        ],
      },
      {
        path: "settings",
        name: "Settings",
        component: () => import("../features/Settings/SettingsView.vue"),
      },
    ],
  },
  {
    path: "/operator/:envid",
    name: "operator",
    component: () => import("../features/OperatorRole/OperatorRoleView.vue"),
    meta: {
      authGuard: true,
    },
  },
  {
    path: "/operator/:envid/jobsheet/:jobsheet",
    name: "operator-jobsheet",
    component: () => import("../features/OperatorRole/OperatorRoleJobView.vue"),
    meta: {
      authGuard: true,
    },
  },
  {
    path: "/signin/:companyname",
    name: "operator-signin",
    component: () => import("../features/Signin/SigninOperatorView.vue"),
  },
  {
    path: "/signin",
    name: "signin",
    component: () => import("../features/Signin/SigninView.vue"),
  },
  
  {
    path: "/signout",
    name: "Sign out",
    component: () => import("../features/Signin/SignoutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { getCurrentUser, loadUserIntoState, authenticatedUser } =
    useAuthStore();
  if (!to.meta.authGuard || authenticatedUser) {
    next();
  } else {
    const currentUser = await getCurrentUser();
    if (currentUser) {
      loadUserIntoState(currentUser);
      next();
    } else {
      next({ name: "signin" });
    }
  }
});

export default router;
